(function () {
  'use strict';

  class Groups {
    constructor(GroupsRest, $cookies, $rootScope) {
      this.GroupsRest = GroupsRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    groupsIndex(groups = {}, callback = angular.noop) {
      return this.GroupsRest.groups().get(groups,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    groupsCreate(group = {}, callback = angular.noop) {
      return this.GroupsRest.groups().save({group: group},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myGroups(groups = {}, callback = angular.noop) {
      return this.GroupsRest.myGroups().get(groups,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myModules(groups = {}, callback = angular.noop) {
      return this.GroupsRest.modules().get(groups,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    setModules(groups = {}, callback = angular.noop) {
      return this.GroupsRest.moduleSet().save(groups,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    unSetModule(groups = {}, callback = angular.noop) {
      return this.GroupsRest.moduleSet().delete(groups,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    groupIndex(group = {}, callback = angular.noop) {
      return this.GroupsRest.group().get({id: group.id},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    assignStudent(group = {}, callback = angular.noop) {
      return this.GroupsRest.group().save(group,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    groupDelete(group = {}, callback = angular.noop) {
      return this.GroupsRest.group().remove(group,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    groupEdit(group = {}, callback = angular.noop) {
      return this.GroupsRest.group().update({group: group, id: group.id},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    groupCreate(group = {}, callback = angular.noop) {
      return this.GroupsRest.group().update(group,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    lomMetadata(lomMetadata = {}, callback = angular.noop) {
      return this.GroupsRest.group().lomMetadata(lomMetadata,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    metrics(metrics = {}, callback = angular.noop) {
      metrics.metrics = 'metrics';
      return this.GroupsRest.group().get(metrics,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    pollMetrics(metrics = {}, callback = angular.noop) {
      metrics.metrics = 'poll-results';
      return this.GroupsRest.group().get(metrics,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    removeStudent(student = {}, callback = angular.noop) {
      return this.GroupsRest.group().removeStudent(student,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Groups
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Groups', Groups);
}());
